
.notice-layout {
	.el-form-item--small.el-form-item {
		margin-bottom: 15px !important;
	}
	.ql-editor {
		padding: 0px !important;
	}
	.title {
		padding: 10px 0 10px 0;
		text-align: center;
		font-size: 18px;
		color: #202020;
		border-bottom: 2px dotted #ccc;
	}
	.title-msg {
		text-align: center;
		margin-top: 10px;
		margin-bottom: 10px;
		span {
			margin-right: 20px;
		}
	}
	.content {
		margin: 15px 0;
		/deep/ img {
			width: 100%;
		}
	}
	.comment-box {
		min-height: 90px;
		line-height: 35px;
		padding: 10px;
		border: 1px solid #ccc;
		margin-bottom: 10px;
	}
	.ReadPageList {
		height: 40px;
		line-height: 40px;
		border-bottom: 1px dotted #ccc;
		span {
			margin-right: 10px;
		}
	}
	.blue-text {
		color: #60adf9;
	}
	.h400 {
		height: 400px !important;
		overflow-y: auto;
	}
}
