.primary-layout {
  padding: 0 20px;
}
.primary-layout .primary-header {
  height: 50px;
}
.primary-layout .w300 /deep/ .el-input__inner {
  width: 300px !important;
}
.primary-layout .w300 .el-button {
  padding: 9px 20px;
}
