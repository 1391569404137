
.primary-layout {
    padding: 0 20px;

    .primary-header {
        height: 50px;
    }

    .w300 {
        /deep/ .el-input__inner {
			width: 300px !important;
		}
        .el-button {
            padding: 9px 20px;
        }
    }
}
