.notice-layout .el-form-item--small.el-form-item {
  margin-bottom: 15px !important;
}
.notice-layout .ql-editor {
  padding: 0px !important;
}
.notice-layout .title {
  padding: 10px 0 10px 0;
  text-align: center;
  font-size: 18px;
  color: #202020;
  border-bottom: 2px dotted #ccc;
}
.notice-layout .title-msg {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.notice-layout .title-msg span {
  margin-right: 20px;
}
.notice-layout .content {
  margin: 15px 0;
}
.notice-layout .content /deep/ img {
  width: 100%;
}
.notice-layout .comment-box {
  min-height: 90px;
  line-height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}
.notice-layout .ReadPageList {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px dotted #ccc;
}
.notice-layout .ReadPageList span {
  margin-right: 10px;
}
.notice-layout .blue-text {
  color: #60adf9;
}
.notice-layout .h400 {
  height: 400px !important;
  overflow-y: auto;
}
